<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Channel</label>
            <b-select
              v-model="filter.channel"
              :options="filterByChannels"
              @change="onFilterChanged"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Promotion</label>
            <b-select
              v-model="filter.promotion"
              :options="filterByPromotions"
              @change="onFilterChanged"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-checkbox
            id="status"
            v-model="filter.status"
            name="status"
            value="1"
            unchecked-value="0"
            switch
            @change="onFilterChanged"
          >
            <strong>Show Active Only?</strong>
          </b-form-checkbox>
          <br>
        </b-col>

        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onShowAddForm"
          >
            Add Campaign
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{
            tableSettings.currentPage * tableSettings.perPage -
              tableSettings.perPage +
              (data.index + 1)
          }}
        </template>

        <template #cell(active)="data">
          {{ Number(data.item.active )=== 1 ? "Yes" : "No" }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowEditForm(row.item)"
            >
              Edit
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-campaign-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <ValidationProvider
            #default="{ errors }"
            name="campaign name"
            vid="campaign_name"
            rules="required|max:100"
          >
            <b-form-group>
              <label for="campaign_name">Campaign Name</label>
              <b-input
                id="campaign_name"
                v-model="campaign.campaign_name"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                placeholder="enter campaign name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="channel"
            vid="channel"
            rules="required"
          >
            <b-form-group>
              <label for="channel">Channel</label>
              <b-form-select
                id="channel"
                v-model="campaign.channel"
                :options="list.channels"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please Select Channel --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="promotion"
            vid="promotion"
            rules="required"
          >
            <div class="form-group">
              <label for="promotion">Promotion</label>
              <b-form-select
                id="promotion"
                v-model="campaign.promotion"
                :options="list.promotions"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please Select Promotion --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="date from"
            vid="date_from"
            rules="required|max:100"
          >
            <b-form-group>
              <label for="date_from">Date From</label>
              <b-input
                id="date_from"
                v-model="campaign.date_from"
                type="date"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="date to"
            vid="date_to"
            rules="required|max:100"
          >
            <b-form-group>
              <label for="date_to">Date To</label>
              <b-input
                id="date_to"
                v-model="campaign.date_to"
                type="date"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="target"
            vid="target"
            rules="required|numeric"
          >
            <b-form-group>
              <label for="target">Target</label>
              <b-input
                id="target"
                v-model="campaign.target"
                type="number"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                placeholder="enter target"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <div
            v-if="state.editing"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="campaign.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              :disabled="state.busy"
            >
              Is Active?
            </b-form-checkbox>
          </div>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? "Update Record" : "Save Record" }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, SupervisorCampaignService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'SupervisorCampaigns',

  middleware: ['auth', 'supervisor'],

  metaInfo () {
    return {
      title: 'Campaigns'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      filter: {
        promotion: 'All',
        channel: 'All',
        status: 0
      },
      list: {
        channels: [],
        promotions: []
      },
      campaign: {
        id: 0,
        active: 0,
        channel: null,
        campaign_name: '',
        promotion: null,
        date_from: null,
        date_to: null,
        target: 0
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          {
            key: 'created_at',
            formatter: this.dateTimeFormatter,
            sortable: true
          },
          { key: 'target' },
          { key: 'campaign_name', sortable: true },
          { key: 'channel.channel_name', label: 'channel name' },
          { key: 'promotion.promotion_name', label: 'promotion name' },
          { key: 'active', label: 'IS ACTIVE?' },
          {
            key: 'updated_at',
            formatter: this.dateTimeFormatter,
            sortable: true
          }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Campaign' : 'Add Campaign'
    },

    filterByChannels () {
      return [{ value: 'All', text: 'All' }].concat(this.list.channels)
    },

    filterByPromotions () {
      return [{ value: 'All', text: 'All' }].concat(this.list.promotions)
    }
  },

  mounted () {
    core.index()
    this.getChannels()
    this.getPromotions()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await SupervisorCampaignService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            filter_channel: this.filter.channel,
            filter_promotion: this.filter.promotion,
            filter_status: this.filter.status
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getChannels () {
      this.state.busy = true
      await SharedListService.getChannels().then(({ data }) => {
        this.list.channels = data.map(({ id, channel_name }) => ({
          text: channel_name,
          value: id
        }))

        this.state.busy = false
      })
    },

    async getPromotions () {
      await SharedListService.getPromotions().then(({ data }) => {
        this.list.promotions = data.map(({ id, promotion_name }) => ({
          text: promotion_name,
          value: id
        }))
      })
    },

    onShowAddForm () {
      this.state.editing = false
      this.campaign.id = 0
      this.campaign.active = 1
      this.campaign.channel = null
      this.campaign.promotion = null
      this.campaign.campaign_name = null
      this.campaign.date_from = null
      this.campaign.date_to = null
      this.campaign.target = null

      this.$bvModal.show('modal-campaign-form')
    },

    onShowEditForm (item) {
      this.state.editing = true
      this.campaign.id = item.id
      this.campaign.active = item.active
      this.campaign.channel = item.channel_id
      this.campaign.promotion = item.promotion_id
      this.campaign.campaign_name = item.campaign_name
      this.campaign.date_from = item.date_from
      this.campaign.date_to = item.date_to
      this.campaign.target = item.target
      this.$bvModal.show('modal-campaign-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Do you really want to create campaign?'

          if (this.state.editing) {
            title = 'Do you really want to save changes?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (!this.state.editing) {
                return this.onFormPostSubmit()
              } else {
                return this.onFormPutSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk(
            'Oppss! There were problem with your inputs.',
            {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger'
            }
          )
        }
      })
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    },

    async onFormPostSubmit () {
      return new Promise((resolve, reject) => {
        this.state.busy = true
        SupervisorCampaignService.post(this.campaign)
          .then(response => {
            this.state.busy = false
            this.$bvModal.hide('modal-campaign-form')
            this.$swal
              .fire({
                icon: 'success',
                title: 'Successful',
                text: response.data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              })
              .then(() => {
                this.$refs.table.refresh()
              })
          })
          .catch(error => {
            this.state.busy = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
                resolve(error)
              }
            }
          })
      })
    },

    async onFormPutSubmit () {
      return new Promise((resolve, reject) => {
        this.state.busy = true
        SupervisorCampaignService.put(this.campaign)
          .then(({ data }) => {
            this.state.busy = false
            this.$bvModal.hide('modal-campaign-form')
            this.$swal
              .fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              })
              .then(() => {
                const row = _.find(this.$refs.table.localItems, {
                  id: data.campaign.id
                })
                row.created_at = data.campaign.created_at
                row.channel.channel_name = data.campaign.channel.channel_name
                row.target = data.campaign.target
                row.campaign_name = data.campaign.campaign_name
                row.promotion_name = data.campaign.promotion.promotion_name
                row.active = data.campaign.active
                row.updated_at = data.campaign.updated_at
              })
          })
          .catch(error => {
            this.state.busy = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
                resolve(error)
              }
            }
          })
      })
    }
  }
}
</script>
